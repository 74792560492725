/* Container */
.trial-residence-list {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Header */
.trial-residence-list h2 {
  text-align: center;
  margin-bottom: 25px;
  font-size: 24px;
  color: #2c3e50;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Filters Section */
.trial-residence-list .filters {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap; /* 支持在小屏幕上换行 */
}

.trial-residence-list input[type="text"],
.trial-residence-list select,
.trial-residence-list input[type="date"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

.trial-residence-list input[type="text"]:focus,
.trial-residence-list select:focus,
.trial-residence-list input[type="date"]:focus {
  border-color: #2980b9;
}

.trial-residence-list input[type="text"] {
  width: 100%;
  max-width: 300px;
}

.trial-residence-list select {
  appearance: none;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNCIgdmlld0JveD0iMCAwIDExIDQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzM1NDUpIj48cGF0aCBkPSJNMS45OTk5NyAwTDYgMy45OTk5OTlMMS45OTk5NyAwTDEuOTk5OTcgMHoiIGZpbGw9IiM4MjgyODIiLz48L2c+PC9zdmc+') no-repeat right 10px center;
  background-size: 12px;
  padding-right: 30px; /* 为下拉箭头添加空间 */
}

/* Table Styling */
.table-container {
  max-width: 100%;
  height: 400px;
  overflow-x: auto;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  color: #333;
  text-align: center; /* 确保所有表格内容和表头都水平居中 */
}

.table-container th,
.table-container td {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  text-align: center; /* 水平居中对齐 */
}

.table-container th {
  background-color: #3498db;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center; /* 表头水平居中 */
}

.table-container td {
  background-color: #fdfdfd; /* 添加背景色 */
}

.table-container tr:nth-child(even) {
  background-color: #f9f9f9; /* 交替背景色 */
}

.table-container tr:hover {
  background-color: #e8f6ff; /* 鼠标悬停背景色 */
}

.table-container .content-cell {
  white-space: normal; /* 允许内容换行显示 */
  word-wrap: break-word; /* 如果有长单词或链接，允许断行 */
  text-align: left; /* 可选：如果希望内容单元格左对齐 */
}

/* Buttons Styling */
.trial-residence-list button {
  padding: 8px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 5px;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 14px;
}

.trial-residence-list button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.trial-residence-list button.delete {
  background-color: #e74c3c;
}

.trial-residence-list button.delete:hover {
  background-color: #c0392b;
}

/* Pagination Styling */
.pagination-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
}

.pagination button {
  padding: 8px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.pagination button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.pagination button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.pagination .active {
  background-color: #2980b9;
  color: white;
  font-weight: bold;
  transform: scale(1.1);
}

.pagination-info {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #333;
}

.pagination-info .page-jump {
  display: flex;
  align-items: center;
  gap: 5px;
}

.pagination-info input {
  width: 60px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  outline: none;
  transition: border-color 0.3s;
}

.pagination-info input:focus {
  border-color: #2980b9;
}

.pagination-info button {
  padding: 8px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.pagination-info button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

/* Modal Styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 500px;
  width: 90%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.modal-content h3 {
  margin-bottom: 15px;
  font-size: 20px;
  color: #2c3e50;
}

.modal-content p {
  font-size: 14px;
  margin-bottom: 8px;
  line-height: 1.6;
  color: #333;
}

.modal-close {
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #3498db;
}

.modal-close:hover {
  color: #2980b9;
}

.modal-body {
  flex-grow: 1;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-bottom: 20px;
  max-height: 60vh; /* 限制模态框内容的最大高度，确保可滚动 */
}

.modal-content button {
  margin-top: 20px;
  padding: 8px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-content button:hover {
  background-color: #2980b9;
}
