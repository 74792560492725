/* 表单容器 */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* 行与行之间的间距 */
  max-width: 600px;
  margin: 20px auto;
  background: #fff;
  padding: 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Arial', sans-serif;
}

/* 表单行布局，确保标签和输入框对齐 */
.form-container div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* 让小屏幕时自动换行 */
}

.form-container label {
  width: 150px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

/* 输入框、选择框、文本框 */
.form-container input,
.form-container select,
.form-container textarea {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* 输入框和选择框的聚焦样式 */
.form-container input:focus,
.form-container select:focus,
.form-container textarea:focus {
  border-color: #3498db;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.2);
  outline: none;
}

/* 文本区域样式 */
.form-container textarea {
  height: 120px; /* 固定高度 */
  resize: vertical; /* 允许垂直缩放 */
}

/* 提交按钮 */
.form-container button {
  padding: 12px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  width: 100%;
  text-align: center;
}

/* 按钮悬停和点击效果 */
.form-container button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.form-container button:active {
  background-color: #1e6f93;
  transform: translateY(0);
}

/* 调整小屏幕上的布局 */
@media (max-width: 768px) {
  .form-container div {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-container label {
    width: 100%;
  }

  .form-container input,
  .form-container select,
  .form-container textarea {
    width: 100%;
  }
}
