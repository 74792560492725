/* src/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #2c3e50;
    color: #ecf0f1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-left {
    display: flex;
    align-items: center;
  }
  
  .sidebar-toggle {
    background: none;
    border: none;
    color: #ecf0f1;
    font-size: 1.5em;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .navbar-brand {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 40px; /* 根据需要调整Logo高度 */
  }
  
  .navbar-menu {
    display: flex;
    align-items: center;
  }
  