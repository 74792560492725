/* src/App.css */
.app-container {
  display: flex;
  height: 100vh;
  margin: 0;
  padding-top: 60px; /* 根据导航栏高度调整 */
}

.sidebar {
  width: 250px;
  padding: 20px;
  background: #2c3e50;
  color: #ecf0f1;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  transition: all 0.3s ease-in-out;
  position: fixed;
  top: 60px; /* 确保侧边栏与导航栏顶部对齐 */
  bottom: 0;
  left: 0;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin: 10px 0;
}

.sidebar .menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar .menu-item:hover {
  background-color: #34495e;
}

.sidebar .arrow {
  border: solid #ecf0f1;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.3s ease;
}

.sidebar .arrow.open {
  transform: rotate(45deg);
}

.sidebar .submenu {
  padding-left: 20px;
  margin-top: 10px;
}

.sidebar .submenu li {
  margin: 5px 0;
}

.sidebar a {
  text-decoration: none;
  color: #bdc3c7;
  display: block;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar a:hover {
  background-color: #34495e;
  color: #ecf0f1;
}

.sidebar a.active {
  background-color: #2980b9;
  color: #ecf0f1;
}

.content {
  flex: 1;
  padding: 20px;
  overflow: auto;
  background-color: #ecf0f1;
  margin-left: 250px; /* 确保内容区域不被侧边栏覆盖 */
  width: calc(100% - 250px); /* 调整内容区域宽度以填充剩余空间 */
  transition: margin-left 0.3s ease;
}

.app-container.sidebar-closed .content {
  margin-left: 0; /* 当侧边栏关闭时，内容区域填满整个宽度 */
  width: 100%;
}
