/* src/components/UserMenu.css */
.user-menu {
    position: relative;
  }
  
  .user-name {
    cursor: pointer;
    color: #ecf0f1;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .user-name:hover {
    background-color: #34495e;
  }
  
  .user-icon {
    margin-right: 8px;
    font-size: 2em; /* 增大图标大小 */
  }
  
  .username-text {
    font-size: 1em;
  }
  
  .dropdown-menu {
    position: absolute;
    left: 0; /* 确保左对齐 */
    top: 100%;
    background-color: #34495e;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    z-index: 1001;
    width: 180px;
    animation: fadeIn 0.3s; /* 添加淡入效果 */
  }
  
  .dropdown-menu button {
    display: block;
    width: 100%;
    padding: 10px;
    background: none;
    border: none;
    color: #ecf0f1;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dropdown-menu button:hover {
    background-color: #2980b9;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  