/* LogList Container */
.log-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px; /* 增加宽度 */
  margin: 20px auto; /* 添加上下外边距 */
  padding: 20px;
  background-color: #f9f9f9; /* 浅灰色背景，提升对比度 */
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* 提高阴影对比 */
}

/* Filters Section */
.log-list .filters {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
  background-color: #ffffff; /* 增加背景色 */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.log-list input[type="text"],
.log-list input[type="date"],
.log-list select {
  padding: 10px;
  border: 1px solid #ddd; /* 更改边框颜色 */
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
  background-color: #fff; /* 白色背景 */
}

.log-list input[type="text"]:focus,
.log-list input[type="date"]:focus,
.log-list select:focus {
  border-color: #2980b9; /* 聚焦时的边框颜色 */
}

.log-list select {
  width: 200px;
}

.log-list button {
  padding: 8px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 14px;
  margin-left: 10px; /* 按钮间距 */
}

.log-list button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.log-list button.delete {
  background-color: #e74c3c;
}

.log-list button.delete:hover {
  background-color: #c0392b;
}

/* Table Styling */
.table-container {
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0; /* 移除内边距以减少空白 */
  overflow-x: auto; /* 使表格在小屏幕上可滚动 */
}

.log-list table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  color: #333;
}

.log-list th, .log-list td {
  padding: 12px 15px;
  text-align: center;
  border-bottom: 1px solid #ddd; /* 增加底部边框 */
  white-space: nowrap; /* 防止文字换行 */
}

.log-list th {
  background-color: #3498db;
  color: white;
  font-weight: bold;
}

.log-list td {
  background-color: #fdfdfd; /* 增加表格行背景色 */
}

.log-list tr:hover td {
  background-color: #f1f1f1; /* 鼠标悬停时的行背景色 */
}

/* 关键样式更新，确保内容完全显示 */
.log-list .content-cell {
  max-width: 100%; /* 移除宽度限制 */
  overflow: visible; /* 移除溢出隐藏 */
  white-space: normal; /* 允许换行 */
  word-wrap: break-word; /* 长单词换行 */
  text-align: left; /* 内容左对齐 */
  font-size: 13px; /* 字体大小略小 */
}

/* Pagination Styling */
.pagination-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
}

.pagination button {
  padding: 8px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 14px;
}

.pagination button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.pagination button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.pagination .active {
  background-color: #2980b9;
  color: white;
  font-weight: bold;
  transform: scale(1.1);
}

.pagination-info {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #333;
}

.pagination-info .page-jump {
  display: flex;
  align-items: center;
  gap: 5px;
}

.pagination-info input {
  width: 60px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  outline: none;
  transition: border-color 0.3s;
}

.pagination-info input:focus {
  border-color: #2980b9;
}

.pagination-info button {
  padding: 8px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.pagination-info button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

/* Modal Styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 500px;
  width: 90%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.modal-content h2 {
  margin-top: 0;
  color: #333;
}

.modal-close {
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #3498db;
}

.modal-close:hover {
  color: #2980b9;
}

.modal-body {
  flex-grow: 1;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-bottom: 20px;
}

.modal-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.modal-content button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}
