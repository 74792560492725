/* MemberList Container */
.member-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1000px;
  margin: 20px auto; /* 增加顶部和底部间距 */
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Filters Section */
.member-list .filters {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px; /* 增加间距 */
  flex-wrap: wrap; /* 在小屏幕上换行 */
}

.member-list input[type="text"],
.member-list select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

.member-list input[type="text"]:focus,
.member-list select:focus {
  border-color: #2980b9;
}

.member-list input[type="text"] {
  width: 100%;
  max-width: 300px;
}

.member-list .filters select {
  appearance: none;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNCIgdmlld0JveD0iMCAwIDExIDQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzM1NDUpIj48cGF0aCBkPSJNMS45OTk5NyAwTDYgMy45OTk5OTlMMS45OTk5NyAwTDEuOTk5OTcgMHoiIGZpbGw9IiM4MjgyODIiLz48L2c+PC9zdmc+') no-repeat right 10px center;
  background-size: 12px;
  padding-right: 30px; /* 为下拉箭头添加空间 */
}

/* Table Styling */
.table-container {
  max-width: 100%;
  height: 400px;
  overflow-x: auto;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.member-list table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  color: #333;
}

.member-list th, 
.member-list td {
  padding: 12px 15px;
  text-align: left;
}

.member-list th {
  background-color: #3498db;
  color: white;
  border-bottom: 2px solid #ddd;
}

.member-list td {
  border-bottom: 1px solid #ddd;
}

.member-list tr:hover {
  background-color: #f1f1f1;
}

.member-list .content-cell {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Buttons Styling */
.member-list button {
  padding: 8px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 5px;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 14px;
}

.member-list button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.member-list button.delete {
  background-color: #e74c3c;
}

.member-list button.delete:hover {
  background-color: #c0392b;
}

/* Pagination Styling */
.pagination-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
}

.pagination button {
  padding: 8px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.pagination button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.pagination button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.pagination .active {
  background-color: #2980b9;
  color: white;
  font-weight: bold;
  transform: scale(1.1);
}

.pagination-info {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #333;
}

.pagination-info .page-jump {
  display: flex;
  align-items: center;
  gap: 5px;
}

.pagination-info input {
  width: 60px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  outline: none;
  transition: border-color 0.3s;
}

.pagination-info input:focus {
  border-color: #2980b9;
}

.pagination-info button {
  padding: 8px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.pagination-info button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

/* Modal Styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 500px;
  width: 90%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.modal-content h2 {
  margin-top: 0;
  color: #333;
}

.modal-close {
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #3498db;
}

.modal-close:hover {
  color: #2980b9;
}

.modal-body {
  flex-grow: 1;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-bottom: 20px;
}

.modal-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-content button:hover {
  background-color: #2980b9;
}
