.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 600px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 5px;
}

.form-container div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-container label {
  width: 150px;
  font-weight: bold;
}

.form-container input, .form-container select, .form-container textarea {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.form-container input[type="checkbox"] {
  width: auto;
  padding: 0;
}

.form-container button {
  align-self: center;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-container button:hover {
  background-color: #0056b3;
}
