/* 确保所有表单元素的 box-sizing 为 border-box，防止宽度问题 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Form Container */
.trial-residence-form {
  max-width: 650px;
  margin: 40px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

/* Form Header */
.trial-residence-form h2 {
  text-align: center;
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 25px;
  letter-spacing: 1px;
}

/* Form Group */
.trial-residence-form .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

/* Labels */
.trial-residence-form label {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
}

/* 统一输入框和选择框的宽度和样式 */
.trial-residence-form input,
.trial-residence-form select {
  width: 100%; /* 设置为 100% 宽度 */
  max-width: 100%; /* 防止溢出 */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  appearance: none; /* 使 select 和 input 的外观保持一致 */
}

/* 日期选择框特殊处理 */
.trial-residence-form input[type="date"] {
  appearance: none; /* 移除默认样式 */
  width: 100%; /* 确保宽度与其他输入框一致 */
}

/* 输入框和选择框聚焦时效果 */
.trial-residence-form input:focus,
.trial-residence-form select:focus {
  border-color: #3498db;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
  outline: none;
}

/* 提交按钮 */
.trial-residence-form button {
  width: 100%;
  padding: 12px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.trial-residence-form button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.trial-residence-form button:active {
  background-color: #1e6f93;
  transform: translateY(0);
}

/* 筛选条件容器 */
.trial-residence-form .filter-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
}

/* 确保筛选框宽度一致 */
.trial-residence-form .filter-container input,
.trial-residence-form .filter-container select {
  width: 100%; /* 设置为 100% 宽度 */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* 响应式设计 */
@media (max-width: 768px) {
  .trial-residence-form .filter-container {
    flex-direction: column;
    gap: 10px;
  }

  .trial-residence-form {
    padding: 20px;
  }

  .trial-residence-form button {
    font-size: 16px;
    padding: 10px;
  }
}
